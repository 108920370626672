@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.create-new-order-wrapper {
  display: flex;
  flex-direction: row;

  .two-input {
    .ant-form-item {
      width: 100%;
    }

    div {
      &:last-child {
        width: 100%;
      }
    }
  }

  .main-page {
    width: 35%;
  }

  .new-order-map-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 65%;
    border-radius: $b-radius;
    padding-left: 30px;
  }
}

.order-list-scroll {
  position: relative;
  overflow: hidden;

  .my-order-list-mobile-wrapper {
    display: flex;
    align-items: center;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.order-view-user-wrapper {
  @include shadowMain();
  border-radius: 10px;
  padding: 15px 20px;

  .my-row {
    cursor: pointer;
  }

  .ant-avatar {
    min-width: 40px;
    background-color: transparent;
  }

  .order-card-name-type-wrapper {
    .transport-type-name-div {
      font-size: 12px;
      color: fade($textColor, 70%);
      font-weight: $fw-regular;
    }

    .user-name-div {
      font-size: 14px;
      color: $blue;
      font-weight: $fw-medium;
    }
  }

  .my-block-title {
    display: none;
  }
}

.order-view-block-header {
  margin-bottom: 10px;

  .my-block-title {
    display: none;
  }

  .order-view-block-name {
    font-size: 16px;
    color: $textColor;
    font-weight: $fw-medium;
  }

  .order-view-block-number {
    font-size: 14px;
    color: $textColor;
    font-weight: $fw-medium;
  }

  .anticon {
    cursor: pointer;
  }
}

.manifest-timeline-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  overflow-y: auto;

  width: 100%;
  min-width: 315px;
  width: auto;

  height: 310px;
  max-height: 310px;
  min-height: 310px;
  border-radius: $b-radius;
  @include shadowMain();

  .manifest-timeline {
    max-height: 310px;
    overflow-x: hidden;
    overflow-y: auto;
    .timeline-header {
      font-size: 16px;
      color: $textColor;
      font-weight: $fw-medium;
      text-align: center;
      padding-top: 10px;
    }

    .ant-timeline {
      height: auto;
      padding: 20px 0 0 20px;

      .ant-timeline-item {
        padding-bottom: 10px;

        .ant-timeline-item-tail {
          border-left: 1px dashed fade($textColor, 50%);
        }

        // .ant-timeline-item-head {
        //   border: 1px solid fade($textColor, 50%);
        // }

        .ant-timeline-item-content {
          padding-right: 30px;
          top: -4px;

          p {
            margin-bottom: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 12px;
            color: fade($textColor, 70%);
            font-weight: $fw-regular;

            &.first-name {
              margin-right: 10px;
              font-weight: $fw-medium;
              color: $textColor;
            }

            &.main-info {
              width: 95%;
            }
          }

          .timeline-info-items {
            display: flex;
            align-items: center;
            min-height: 15px;
          }
        }

        &.timeline-done {
          .ant-timeline-item-tail {
            border-left: 1px dashed fade($green, 50%);
          }

          // .ant-timeline-item-head {
          //   border: 1px solid transparent;
          // }

          .ant-timeline-item-content {
            p {
              &.main-info {
                color: $green;
              }
            }
          }
        }
      }
    }
  }
  .end-start-date {
    height: 50px;
    border: 1px solid $borderColor;
    text-align: center;
    border-radius: $b-radius;
    margin-bottom: 2px;
    @include shadowMain();
    padding: 10px;
  }
}

.about-order-wrapper {
  margin-top: 20px;

  .about-order-row {
    display: flex;
    margin-bottom: 10px;

    .antiocn-div {
      min-width: 12px;

      .anticon {
        display: flex;
        padding-top: 5px;
      }
    }

    .type-name-wrapper {
      margin-left: 10px;

      .about-order-type {
        font-size: 12px;
        color: fade($textColor, 70%);
        font-weight: $fw-regular;
      }

      .about-order-name {
        font-weight: $fw-medium;
        color: $textColor;
        font-size: 12px;
      }
    }
  }
}

.user-view-activity-wrapper {
  margin: 0;
  padding: 0;
  .ant-tabs {
    padding: 5px;
    .ant-tabs-nav-list {
      .ant-tabs-ink-bar {
        width: 0 !important;
      }
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 10px;
    }
    .ant-tabs-nav {
      position: static;
    }
  }
  .ant-tabs-tab {
    border-radius: 20px;
    height: 10px;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;

    &.ant-tabs-tab-active {
      color: #0076ff;
      background-color: rgba(0, 118, 255, 0.1);
      border: none;
      border-bottom: 2px solid transparent !important;
    }
  }
}

.order-number-with-icon {
  display: flex;
  align-items: center;

  .edit-icon {
    display: flex;
    padding-left: 10px;
  }
}

@media screen and (max-width: 991px) {
  .my-order-lists-main-wrapper {
    overflow-x: auto;
    .order-list-wrapper {
      overflow: visible;
      .my-order-list-panel {
        min-width: 280px;
        // .one-order-block-wrapper {
        //   min-width: 280px;
        //   max-width: 280px;
        // }
      }
    }
  }
  .create-new-order-wrapper {
    flex-direction: column;
    .new-order-map-wrapper {
      width: 100%;
      padding-left: 0;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column-reverse;
    }
    .map-wrapper-responsive {
      padding-top: 20px;
    }
    .main-page {
      margin-left: 0;
      width: 100%;
      max-width: 100% !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .site-layout {
    .site-layout-background {
      padding: 0;
    }
  }
  .my-order-lists-main-wrapper {
    flex-direction: column;
    padding: 0;
    overflow: hidden;
    .order-list-wrapper {
      .ant-form {
        margin-left: 15px;
      }
      .title {
        padding: 15px;
      }
      .my-order-list-panel {
        padding: 0 15px;
        max-height: calc(100vh - 117px);
        min-width: auto;
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: transparent;
        }
        &::-webkit-scrollbar {
          width: 0;
        }
        // .one-order-block-wrapper {
        //   min-width: 100%;
        //   max-width: 100%;
        // }
      }
    }
  }
  .create-new-order-wrapper {
    .new-order-map-wrapper {
      padding: 0 15px 20px 15px;
    }
    &.order-edit-wrapper {
      padding-top: 0;
    }
  }

  .user-list-card-with-map {
    .some-monifest-infos {
      width: 100%;
    }
  }
}

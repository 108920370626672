@import '../../styles/colors.scss';
@import '../../styles/variables.scss';
@import './styleResponsive.scss';

.my-order-lists-main-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 5px;
  overflow: hidden;

  // :last-child {
  //   margin-right: 0;
  // }

  .order-show-archive-section {
    max-height: calc(100vh - 100px);
    width: 100%;
    text-align: center;
  }

  .title {
    padding: 10px 15px;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    font-weight: $fw-medium;
  }
  .count {
    padding-right: 10px;
    padding-left: 10px;
    background-color: $blue;
    color: $white;
    border-radius: 10px;
    min-width: 25px;
    text-align: center;
    font-size: 12px;
    height: 18px;
  }
}

.my-order-lists-main-page > * {
  margin-right: 10px;
}

.my-order-list-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 10px;
  height: 100%;
  max-height: calc(100vh - 126px);

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 100px;
    overflow: hidden;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: fade($textColor, 5%);
    border-radius: 100px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: fade($textColor, 50%);
    border-radius: 100px;
  }
}

.one-order-block-wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;
  height: 240px !important;
  // min-height: 195px !important;
  margin-bottom: 15px;
  cursor: pointer;
  border-radius: $b-radius;
  justify-content: space-between;
  @include shadowMain();

  .number-div {
    font-weight: $fw-medium;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .branch-div {
    color: $labelColorGrey;
    font-size: 12px;
    white-space: normal;
    text-align: left;
  }

  .date-div {
    color: $labelColorGrey;
    font-size: 12px;
    white-space: normal;
    text-align: right;
  }

  .anticon-calendar {
    min-width: 26px;
    height: 26px;
    border: 1px dashed #3e3d4d;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar-name {
    justify-content: flex-start;
    padding-top: 10px;

    .ant-avatar {
      margin-right: 8px;
      background-color: transparent;
      min-width: 26px;
      height: 26px;
      width: 26px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.border-dash {
        border: 1px dashed #3e3d4d;
      }

      svg {
        fill: #3e3d4d;
        width: 13px;
        height: 13px;
        display: flex;
      }
    }

    .number-div {
      font-weight: $fw-medium;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .callbtn-with-avatar {
    // padding-top: 13px;

    .my-block-title {
      display: none;
    }

    .avatar-name {
      padding-top: 0;
      width: 78%;
    }

    .user-profile-letters {
      min-width: 26px;
      max-width: 26px;
      height: 26px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }

    .ant-btn {
      &.ant-btn-circle {
        max-width: 26px;
        min-width: 26px;
        height: 26px;

        .anticon {
          width: 13px;
          height: 13px;
          display: flex;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .ant-timeline {
    height: auto;
  }
}

.order-list-wrapper {
  width: 100%;
  overflow: hidden;

  .ant-form {
    padding: 15px;
    background-color: #1e419c;
    border-radius: $b-radius;
    margin-left: 11px;
    margin-right: 16px;
    margin-bottom: 10px;

    .title {
      padding: 0;
      margin-bottom: 0;
    }

    .ant-btn-primary {
      width: 96px;
    }

    .ant-checkbox-wrapper {
      span {
        color: $white;
        font-size: 12px;
        font-weight: $fw-regular;
      }
    }

    .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector {
      border: 1px solid none;
      background-color: $componentBackground !important;
    }
  }
}

.ant-timeline {
  padding-top: 10px;
  padding-bottom: 10px;

  .ant-timeline-item {
    padding-bottom: 0;

    .ant-timeline-item-head-custom {
      height: 10px;
      display: flex;
      padding: 0;
    }

    .ant-timeline-item-content {
      white-space: normal;
      width: 90%;
      text-overflow: ellipsis;
      top: -6.2px;
    }
  }
}

.my-row-flex-end {
  align-items: flex-end;
}

.create-new-order-wrapper {
  display: flex;
  flex-direction: row;

  .two-input {
    .ant-form-item {
      width: 100%;
      .ant-badge.my-text-with-badge {
        color: $labelColorGrey;
        font-size: 12px;
        .ant-badge-count {
          color: $labelColorGrey;
          font-size: 10px;
        }
      }
    }

    div {
      &:last-child {
        width: 100%;
      }
    }
  }

  .order-view-two-blocks {
    width: 100%;
    display: flex;
    max-width: 850px;
    .main-page {
      width: 50%;
      max-width: 100% !important;

      &:nth-child(2) {
        margin-top: 30px;
        border-left: 1px solid $borderColor;
        padding-left: 15px;
      }

      &:nth-child(1) {
        padding-right: 5px;
      }
    }

    // .order-timeline-wrapper {
    //   .ant-timeline {
    //     padding-left: 0 !important;
    //   }
    // }

    // .order-timeline-with-point {
    //   .ant-timeline {
    //     .ant-timeline-item {
    //       .ant-timeline-item-head {
    //         // border: 1px solid transparent;
    //       }
    //       .ant-timeline-item-content {
    //         width: 90%;
    //         display: block;
    //         display: -webkit-box;
    //         -webkit-line-clamp: 2;
    //         -webkit-box-orient: vertical;
    //         text-overflow: ellipsis;
    //         white-space: normal;
    //         overflow: hidden;
    //         height: 44px;
    //       }
    //     }
    //   }
    // }
  }

  .btn-wrapper-rate {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .new-order-view-map-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: calc(100% - 480px) !important;
    border-radius: $b-radius;
    padding-left: 15px !important;
    position: fixed;
    right: 30px;
    top: 20px;
    transition: all 0.2s;
    &.map-wrapper-responsive {
      width: calc(100% - 480px) !important;
      right: 15px;
      .map-with-height {
        width: 100%;
      }
      &.menu-open-for-map {
        width: calc(100% - 1100px) !important;
      }
    }
    &.menu-open-for-map {
      width: calc(100% - 770px) !important;
    }
    .ymaps-2-1-79-map {
      border-radius: 20px;
      overflow: hidden;
      width: 100% !important;
      .ymaps-2-1-79-inner-panes {
        border-radius: 20px;
      }
    }
  }

  .new-order-map-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: calc(100% - 800px) !important;
    border-radius: $b-radius;
    padding-left: 15px !important;
    position: fixed;
    right: 30px;
    top: 20px;
    transition: all 0.2s;
    &.map-wrapper-responsive {
      width: calc(100% - 950px) !important;
      right: 15px;
      .map-with-height {
        width: 100%;
      }
      &.menu-open-for-map {
        width: calc(100% - 1100px) !important;
      }
    }
    &.menu-open-for-map {
      width: calc(100% - 770px) !important;
    }
    .ymaps-2-1-79-map {
      border-radius: 20px;
      overflow: hidden;
      width: 100% !important;
      .ymaps-2-1-79-inner-panes {
        border-radius: 20px;
      }
    }
  }
}

.order-list-scroll {
  position: relative;
  overflow: hidden;

  .my-order-list-mobile-wrapper {
    display: flex;
    align-items: center;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.order-view-customer-driver-wrapper {
  border-radius: 10px;
  padding: 20px;
  background-color: $componentBackground;
  min-height: 126px;

  .order-view-user-wrapper {
    margin-bottom: 10px;
    box-shadow: none;
    border-radius: 0;
    padding: 0;

    .my-row {
      cursor: pointer;
    }

    .ant-btn.ant-btn-circle {
      height: 26px;
      width: 26px;
      min-width: 26px;
      background-color: transparent;

      span {
        width: 13px;
        height: 13px;
        display: flex;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .ant-avatar {
      min-width: 40px;
      background-color: transparent;
      .anticon-user {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: fade($blue, 7%);
      }
    }

    .order-card-name-type-wrapper {
      .transport-type-name-div {
        font-size: 12px;
        color: fade($textColor, 70%);
        font-weight: $fw-regular;
      }

      .user-name-div {
        font-size: 14px;
        color: $blue;
        font-weight: $fw-medium;
      }
    }

    .my-block-title {
      display: none;
    }
  }

  .customer-or-driver-info {
    padding: 7px 0 !important;
    border-top: 1px solid $borderColor;

    .first-item {
      color: $labelColorGrey;
      font-weight: $fw-regular;
    }

    .second-item {
      color: $textColor;
      font-weight: $fw-regular;
    }
  }
}

.order-view-block-header {
  margin-bottom: 10px;

  .my-block-title {
    display: none;
  }

  .order-view-block-name {
    font-size: 16px;
    color: $textColor;
    font-weight: $fw-medium;
  }

  .order-view-block-number {
    font-size: 14px;
    color: $textColor;
    font-weight: $fw-medium;
  }
}

.order-timeline-wrapper {
  margin-top: 15px;

  .timeline-header {
    font-size: 16px;
    color: $textColor;
    font-weight: $fw-medium;
  }

  .ant-timeline {
    height: auto;
    padding: 15px 0 0 0;

    .ant-timeline-item {
      padding-bottom: 10px;

      .ant-timeline-item-tail {
        border-left: 1px dashed fade($textColor, 50%);
      }

      // .ant-timeline-item-head {
      //   border: 1px solid fade($textColor, 50%);
      // }

      // .ant-timeline-item-content {
      // width: 100%;
      // top: -4px;
      // p {
      //   margin-bottom: 0;
      //   text-overflow: ellipsis;
      //   overflow: hidden;
      //   font-size: 12px;
      //   color: fade($textColor, 70%);
      //   font-weight: $fw-regular;

      //   &.first-name {
      //     margin-right: 10px;
      //     font-weight: $fw-medium;
      //     color: $textColor;
      //   }

      //   &.main-info {
      //     width: 95%;
      //   }
      // }

      // .timeline-info-items {
      //   display: flex;
      //   align-items: center;
      //   min-height: 15px;
      // }

      // .ant-collapse {
      //   margin-right: 30px;
      //   background-color: transparent;
      //   border-radius: 0;
      //   border-style: none;
      //   .ant-collapse-item {
      //     border-radius: 0;
      //     border-style: none;
      //     .ant-collapse-header {
      //       padding: 0;
      //       display: flex;
      //       align-items: center;
      //       line-height: normal;
      //       flex-direction: row-reverse;
      //       justify-content: flex-end;
      //       .main-info {
      //         margin-right: 0;
      //         width: fit-content;
      //       }
      //       .anticon-right {
      //         position: relative;
      //         right: 0;
      //         top: 6px;
      //         margin-left: 5px;
      //         svg {
      //           transform: rotate(270deg) !important;
      //         }
      //       }
      //     }
      //     &.ant-collapse-item-active {
      //       .ant-collapse-header {
      //         .anticon-right {
      //           svg {
      //             transform: rotate(270deg) !important;
      //           }
      //         }
      //       }
      //     }
      //     .ant-collapse-content {
      //       border-style: none;
      //       .ant-collapse-content-box {
      //         padding: 0;
      //         padding-top: 10px;
      //         .timeline-info-items {
      //           display: flex;
      //           flex-direction: column;
      //           align-items: flex-start;
      //           .first-name {
      //             margin-right: 0;
      //             margin-bottom: 10px;
      //           }
      //           p {
      //             display: flex;
      //             align-items: center;
      //             .anticon {
      //               margin-right: 5px;
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
      // }

      &.timeline-done {
        .ant-timeline-item-tail {
          border-left: 1px dashed fade($green, 50%);
        }

        // .ant-timeline-item-head {
        //   border: 1px solid transparent;
        // }

        .ant-timeline-item-content {
          p {
            &.main-info {
              color: $green;
            }
          }
        }
      }
    }
  }
}

.order-view-description {
  .about-order-wrapper {
    margin-top: 10px;

    .about-order-row {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $borderColor;
      padding: 10px 0;
      margin-bottom: 0;
      .about-order-type {
        font-size: 12px;
        color: fade($textColor, 70%);
        font-weight: $fw-regular;
      }

      .about-order-name {
        font-weight: $fw-medium;
        color: $textColor;
        font-size: 12px;
      }
    }
  }

  .divider-line {
    border-bottom: 1px solid $borderColor;
  }

  .order-view-services-prices {
    border-top: 1px solid $borderColor;

    .product-row {
      padding: 7px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 58px;
      align-items: center;
      border-bottom: 1px solid $borderColor;

      .row-first-item {
        font-size: 12px;
        color: fade($textColor, 70%);
        font-weight: $fw-regular;
        width: 43%;
        .name {
          margin-bottom: 5px;
        }
      }
      .row-second-item {
        font-weight: $fw-medium;
        color: $textColor;
        font-size: 12px;
        .currency-symbol {
          margin-left: 5px;
        }
        .item-unit {
          margin-left: 5px;
        }
      }
    }

    .serives-row {
      padding: 7px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 40px;
      align-items: center;
      border-top: 1px solid rgba(112, 112, 112, 0.14);

      .row-first-item {
        font-size: 12px;
        color: fade($textColor, 70%);
        font-weight: $fw-regular;
      }

      .row-second-item {
        font-weight: $fw-medium;
        color: $textColor;
        font-size: 12px;
        .currency-symbol {
          margin-left: 5px;
        }
      }
    }
  }
}

.order-view-rating {
  .row-second-item {
    margin-top: 8px;
  }
  .my-block-title {
    margin-top: 20px;
    border-top: 1px solid $borderColor;
  }
}
.image-preview-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 7px;
  flex-wrap: wrap;
  margin-top: 20px;

  .image-item {
    width: 90px;
    height: 90px;
    border-radius: 10px;
  }
}

.order-number-with-icon {
  display: flex;
  align-items: center;
  overflow: hidden;
  min-height: 36px;

  .my-row {
    overflow: hidden;

    .anticon {
      width: 14px;

      svg {
        width: 100%;
      }
    }
  }

  .edit-icon {
    display: flex;
    padding: 10px;
  }
}

.order-item-price-list-wrapper {
  h5 {
    font-size: 16px;
    color: #474657;
    font-weight: 500;
  }

  .order-list-item {
    border-top: 1px solid $borderColor;
    padding-top: 15px;
    .add-price-list-row {
      // align-items: flex-flex;

      .ant-form-item-label {
        label {
          white-space: nowrap;
        }
      }

      .ant-form-item {
        width: 100%;
      }

      .full_with {
        &:nth-child(1) {
          width: 93.5%;
        }
      }

      &:last-child {
        .full_with {
          &:nth-child(1) {
            width: 93.5%;
          }
        }
      }
    }
    &:nth-child(2) {
      padding-top: 0;
      border-top: 0px solid transparent;
      .dynamic-delete-button {
        top: 40px;
      }
    }
  }
}

.order-tracking-wrapper {
  padding-left: 10px;
  padding-right: 20px;
  .order-view-two-blocks {
    // padding-top: 18px;
    .main-page {
      .my-block-title {
        margin-bottom: 0 !important;
      }
      // &:nth-child(2) {
      //   margin-top: 35px;
      // }
    }
  }
}

.duration-distance-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .duration-distance {
    height: 36px;
    margin-right: 15px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 1px solid $blue;
    background-color: $white;
    box-shadow: 0px 3px 6px #00000029;
    padding: 0 20px;
    cursor: pointer;
    h5 {
      color: $blue;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: $fw-medium;
      & + h5 {
        margin-left: 10px;
      }
    }
    &.active {
      background-color: $blue;
      box-shadow: none;
      h5 {
        color: $white;
      }
    }
  }
}

.show-paths-block-wrapper {
  .ant-list-bordered {
    border-style: none;
  }
  .ant-list-item {
    &.draggble {
      padding: 0;
      border-style: none;
      margin: 0;
      box-shadow: none !important;
      & + .draggble {
        margin-top: 15px;
      }
    }
  }
  .pathway-with-color-circle {
    display: flex;
    align-items: center;
    width: 100%;
    .pathway-circle {
      min-width: 14px;
      height: 14px;
      border-radius: 50%;
      margin-right: 6px;
    }
    .ordering-icon {
      cursor: grab;
      margin-left: 15px;
    }
    .show-paths-block-inputs {
      background-color: fade($componentBackground, 40%);
      border-radius: 18px;
      padding: 10px;
      width: 100%;
      .input-with-checkpoint {
        flex-direction: column-reverse;
        position: relative;
        align-items: flex-start;
        .ant-form-item {
          width: 100%;
          &:nth-child(2) {
            position: absolute;
            right: 10px;
            width: auto;
            top: -12px;
            .ant-checkbox-wrapper {
              display: flex;
              align-items: center;
              flex-direction: row-reverse;
              .ant-checkbox + span {
                font-size: 12px;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
      // .ant-collapse {
      //   .ant-collapse-item {
      //     .ant-collapse-header {
      //       padding: 0;
      //       display: flex;
      //       justify-content: center;
      //       flex-direction: row-reverse;
      //       color: $blue;
      //       font-weight: 500;
      //       .ant-collapse-arrow {
      //         position: relative;
      //         top: 0;
      //         vertical-align: middle;
      //         left: 5px;
      //         transform: rotate(90deg);
      //       }
      //     }
      //     &.ant-collapse-item-active {
      //       .ant-collapse-header {
      //         .ant-collapse-arrow {
      //           transform: rotate(180deg);
      //         }
      //       }
      //     }
      //     .ant-collapse-content {
      //       .ant-collapse-content-box {
      //         padding: 0;
      //         .ant-row {
      //           margin-bottom: 0;
      //         }
      //         .ant-form-item {
      //           width: 100%;
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
  .add-waypoint-btn {
    color: $blue;
    font-weight: 500;
    font-size: 14px;
    padding: 10px 0;
    cursor: pointer;
  }
}

.pathway-drag-line {
  height: 130px !important;
  border: 2px dashed $blue !important;
}

.service-amount-row {
  align-items: flex-start;
  .my-select-item {
    flex: 1;
  }
  .ant-checkbox-wrapper {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 10px;
    .ant-checkbox + span {
      padding-right: 0;
      padding-left: 0;
      color: $labelColorGrey;
      font-size: 12px;
      font-weight: 400;
      padding-bottom: 5px;
    }
    .ant-checkbox {
      width: fit-content;
    }
  }
}

.new-checkbox-form {
  .ant-form-item {
    margin-bottom: 10px;
  }
}

.ymap-input {
  width: 100% !important;
  left: 0 !important;
  top: 40px !important;
  .ymaps-2-1-79-search__suggest {
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
      0 9px 28px 8px rgb(0 0 0 / 5%) !important;
    border-radius: 2px;
    .ymaps-2-1-79-search__suggest-highlight {
      font-weight: 400;
    }
    .ymaps-2-1-79-search__suggest-item {
      height: 32px;
      font-size: 14px;
      display: flex;
      align-items: center;
      font-weight: 400;
    }
    .ymaps-2-1-79-search__suggest-item_selected_yes {
      background-color: #00a7f4;
      color: $white;
    }
  }
}

.formNumber {
  width: 100%;
}

@media screen and (max-width: 1300px) {
  .create-new-order-wrapper {
    flex-wrap: wrap;
    .new-order-map-wrapper {
      &.map-wrapper-responsive {
        width: 100% !important;
        padding-left: 0 !important;
        position: relative;
        right: 0;
        flex: auto;
        &.menu-open-for-map {
          width: 100% !important;
        }
        .map-with-height {
          flex-direction: column-reverse;
          display: flex;
          padding-bottom: 15px;
          .ymaps-2-1-79-map {
            width: 100% !important;
            border-radius: 20px;
            .ymaps-2-1-79-inner-panes {
              border-radius: 20px;
            }
          }
          .duration-distance-wrapper {
            .duration-distance {
              margin-bottom: 15px;
              margin-top: 0;
            }
          }
        }
      }
    }
    .order-view-two-blocks {
      width: 100%;
      max-width: 100%;
      .main-page {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .my-order-lists-main-wrapper {
    overflow-x: auto;
    .order-list-wrapper {
      overflow: visible;
      .my-order-list-panel {
        min-width: 280px;
        .one-order-block-wrapper {
          min-width: 280px;
          max-width: 280px;
        }
      }
    }
  }
  .create-new-order-wrapper {
    // background-color: red;
    flex-direction: column;
    .order-view-two-blocks {
      flex-wrap: wrap;
      flex-direction: column;
      .main-page {
        width: 100%;
        &:nth-child(1) {
          padding-right: 0;
        }
        &:nth-child(2) {
          padding-left: 0;
          border-left: none;
          margin-top: 0px;
        }
      }
    }
    .new-order-map-wrapper {
      width: 100% !important;
      padding-left: 0 !important;
      display: flex;
      flex-direction: column-reverse;
      position: relative;
      right: 0;
    }
  }

  .duration-distance-wrapper {
    .duration-distance {
      margin-top: 0;
      margin-bottom: 15px;
    }
  }

  .my-order-lists-main-wrapper {
    .date-div {
      white-space: nowrap;
    }
  }
  .order-number-with-icon {
    min-height: fit-content;
  }

  .create-new-order-wrapper {
    .new-order-view-map-wrapperrr {
      margin-bottom: 10px;
      &.map-wrapper-responsive {
        padding-top: 5px !important;
        padding-left: 6px !important;
        margin-bottom: 20px !important;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .site-layout {
    .site-layout-background {
      padding: 0;
    }
  }
  .my-order-lists-main-wrapper {
    flex-direction: column;
    padding: 0;
    overflow: hidden;
    .order-list-wrapper {
      .title {
        padding: 15px;
      }
      .my-order-list-panel {
        padding: 0 15px;
        max-height: calc(100vh - 117px);
        min-width: auto;
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: transparent;
        }
        &::-webkit-scrollbar {
          width: 0;
        }
        .one-order-block-wrapper {
          min-width: 100%;
          max-width: 100%;
        }
      }
    }
  }
  .create-new-order-wrapper {
    padding-top: 30px;
    .new-order-map-wrapper {
      padding: 0px 15px 20px 15px;
      padding-left: 15px !important;
    }
    .order-view-two-blocks {
      .main-page {
        &:nth-child(1) {
          padding-right: 15px;
        }
        &:nth-child(2) {
          padding-left: 15px;
        }
      }
    }
  }
  .create-new-order-wrapper {
    .new-order-view-map-wrapperrr {
      margin-bottom: 10px;
      &.map-wrapper-responsive {
        padding-top: 5px !important;
        padding-left: 8px !important;
        margin-bottom: 20px !important;
      }
    }
  }
}

.order-notification-wrapper {
  .my-block-title {
    margin-bottom: 10px;
  }
  .ant-collapse
    > .ant-collapse-item
    .ant-collapse-header-collapsible-only
    .ant-collapse-header-text {
    flex: auto;
  }
  .my-row {
    align-items: baseline;
  }
  .order-notification-second-row {
    margin-left: 30px;
    color: fade($textColor, 70%);
  }
}

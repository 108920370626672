@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

@media screen and (max-width: 991px) {
  .my-block {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 480px) {
  .my-block {
    padding: 15px;
  }
}

@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.three-dashboard-block {
  display: flex;
  .ymaps-2-1-77-map {
    width: 100% !important;
  }
}

.dashboard-left-side-wrapper {
  min-width: 400px;
  max-width: 400px;
  margin-right: 10px;
  .my-order-list-panel {
    padding-left: 0;
    max-height: calc(100vh - 80px) !important;
  }
}

.dashbaord-customer-activity-with-map {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-right: 10px;
  .dashbaord-customer-activity {
    display: flex;
    align-items: center;
    .dashbaord-customer-activity-item {
      padding: 0 10px;
      color: $labelColorGrey;
      border-radius: 10px;
      text-align: center;
      font-size: 12px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      text-transform: uppercase;
      cursor: pointer;
      &.active {
        color: #0076ff;
        background-color: fade($blue, 10%);
      }
    }
  }
  .activity-map-for-responsive {
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $blue;
    cursor: pointer;
    display: none;
    svg {
      g {
        opacity: 1;
      }
    }
  }
}

.dashboard-customer-block-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 7px;
  border-radius: 10px;
  .ant-avatar {
    background-color: transparent;
    width: 34px;
    min-width: 34px;
    height: 34px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .ant-badge {
    .ant-badge-dot {
      background: #1fc97c;
      width: 10px;
      height: 10px;
      border: 1px solid $white;
      top: 30px;
      right: 5px;
    }
  }
  .dashboard-customer-block-item-name {
    margin-left: 6px;
    color: $textColor;
    font-weight: $fw-medium;
    font-size: 14px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    background-color: fade($blue, 10%);
  }
  &.active {
    background-color: $blue;
    .dashboard-customer-block-item-name {
      color: $white;
    }
    .ant-avatar {
      background-color: fade($white, 80%);
    }
  }
}

.dashboard-right-side-wrapper {
  // width: 700px !important;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 3px 6px fade(#000, 16%);
  position: relative;
  right: 0;
  padding: 15px;
  height: calc(100vh - 20px);
  // margin-left: 10px;
  border-radius: $b-radius;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 100px;
    overflow: hidden;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: fade($textColor, 5%);
    border-radius: 100px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: fade($textColor, 50%);
    border-radius: 100px;
  }

  .right-block-title {
    font-size: 16px;
    font-weight: $fw-medium;
    margin-bottom: 10px;
  }

  .dashboard-right-side-driver-block {
    .order-view-user-wrapper {
      margin-bottom: 13px;
      box-shadow: none;
      border-radius: 0;
      padding: 0;

      .my-row {
        cursor: pointer;
      }

      .ant-badge {
        .ant-badge-dot {
          background: #1fc97c;
          width: 10px;
          height: 10px;
          border: 1px solid $white;
          top: 36px;
          right: 7px;
        }
      }

      .order-card-name-type-wrapper {
        .transport-type-name-div {
          font-size: 12px;
          color: fade($textColor, 70%);
          font-weight: $fw-regular;
        }

        .user-name-div {
          font-size: 14px;
          color: $blue;
          font-weight: $fw-medium;
        }
      }

      .my-block-title {
        display: none;
      }
    }

    .customer-or-driver-info {
      padding: 10px 0;
      border-top: 1px solid $borderColor;
      .first-item {
        color: $labelColorGrey;
        font-weight: $fw-regular;
      }
      .second-item {
        color: $textColor;
        font-weight: $fw-regular;
      }
      &:last-child {
        border-bottom: 1px solid $borderColor;
        margin-bottom: 10px;
      }
    }
  }
  .dashboard-block-wrapper {
    max-height: 200px;
    .driver-order-status {
      border-radius: 100px;
      width: fit-content;
      padding: 2px 10px;
      font-size: 12px;
      font-weight: $fw-medium;
      margin: 5px 0;
      &.orange {
        background-color: fade(#ff9300, 11%);
        color: #ff9300;
      }
      &.yellow {
        background-color: fade(#ffe600, 11%);
        color: #ffe600;
      }
      &.blue {
        background-color: fade($blue, 11%);
        color: var(--mainColor);
      }
      &.green {
        background-color: fade(#1fc97c, 11%);
        color: #1fc97c;
      }
      &.red {
        background-color: fade(#ff2255, 11%);
        color: #ff2255;
      }
    }
  }
  .dashboard-right-close-icon {
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
  }
}

.dashboard-map-wrapper {
  width: 100%;
  div {
    height: calc(100vh - 40px) !important;
  }
  .ymaps-2-1-79-map {
    border-radius: 20px;
    width: 100% !important;
    .ymaps-2-1-79-inner-panes {
      border-radius: 20px;
    }
  }
}

.dashboard-right-content-drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-wrapper-body {
      .ant-drawer-body {
        padding: 0;
        .dashboard-right-side-wrapper {
          height: 100%;
          right: 0;
          margin-left: 0;
        }
      }
    }
  }
}

.map-dashboard-drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .close-icon-map-drawer {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          float: right;
          z-index: 1;
          background-color: $white;
          position: relative;
          cursor: pointer;
          border-radius: 50%;
          svg {
            g {
              path {
                fill: $blue;
              }
            }
          }
        }
        .ymaps-2-1-79-map {
          border-radius: 20px;
          height: 100% !important;
          .ymaps-2-1-79-inner-panes {
            border-radius: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .dashbaord-customer-activity-with-map {
    .activity-map-for-responsive {
      display: flex;
    }
  }
  .dashboard-left-side-wrapper {
    min-width: 100%;
    margin-right: 0;
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .three-dashboard-block {
    padding-top: 30px;
  }
  .dashboard-right-side-wrapper {
    height: 97vh;
    .dashboard-right-close-icon {
      top: 10px;
    }
  }
}

@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.user-edit-icon-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 14px;
  margin-top: 14px;

  p {
    padding: 0;
    margin: 0;
    margin-left: 16px;
  }
}

.user-view-map-wrapper {
  width: calc(100% - 300px) !important;
  border-radius: $b-radius;
  padding-left: 15px !important;
  transition: all 0.2s;
  &.menu-open-for-map {
    width: calc(100% - 300px) !important;
  }
  .ymaps-2-1-79-map {
    border-radius: 20px;
    overflow: hidden;
    width: 100% !important;
    .ymaps-2-1-79-inner-panes {
      border-radius: 20px;
    }
  }
}

.user-list-card-with-map {
  display: flex;
  align-items: center;
  margin-left: 10px;
  .user-view-map {
    // margin-left: 20px;
    width: 100%;
    overflow: hidden;
    border-radius: $b-radius;
  }
}

.user-view-list-item {
  @include shadowMain();
  border-radius: $b-radius;
  padding: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  cursor: grab;
  .draggable-icon {
    padding: 10px;
    margin-right: 10px;
    display: flex;
  }
  .list-item {
    margin-right: auto;
    .list-item-title {
      font-size: 12px;
      font-weight: $fw-regular;
      color: $lightGray;
    }
    .list-item-info {
      color: $textColor;
      font-size: 14px;
      font-weight: $fw-medium;
      display: flex;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        font-weight: $fw-regular;
        margin-left: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .list-item-info.order-number {
      cursor: pointer;
      color: var(--mainColor);
    }
    // &.list-item-small {
    //   width: 150px;
    // }
    &.list-item-small.order-number {
      max-width: 110px;
    }
    &.list-item-large {
      width: 30%;
    }
  }
}

.user-view-activity-wrapper {
  .list-section {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 320px);
  }
  overflow: hidden !important;
  .user-view-activity-header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    .user-view-activity-header-item {
      padding: 0 10px;
      color: $labelColorGrey;
      border-radius: 10px;
      text-align: center;
      font-size: 12px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: $fw-medium;
      text-transform: uppercase;
      cursor: pointer;
      &.active {
        color: var(--mainColor);
        background-color: fade($blue, 10%);
      }
    }
  }
  .ant-list-bordered {
    border-style: none;
    border-radius: 0;
    .ant-list-item {
      padding: 0;
      border-style: none;
      margin-right: 0;
    }
  }
}

.draggble-line {
  border-bottom: 2px dashed var(--mainColor) !important;
  margin-top: 5px !important;
}

.register-after-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .back-to-login {
    position: absolute;
    left: 25px;
    top: 30px;
    display: flex;
    align-items: center;
    color: var(--mainColor);
    cursor: pointer;
    span {
      margin-right: 5px;
    }
  }
  .send-email-icon {
    span {
      width: 100%;
      height: 100%;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .send-email-text {
    text-align: center;
    padding: 30px 10px;
    font-size: 12px;
    font-weight: $fw-medium;
    max-width: 460px;
  }

  .send-email-title {
    margin-bottom: -20px;
    padding-top: 10px;
    font-weight: $fw-medium;
    font-size: 24px;
  }
}

.login-page-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  margin-top: 50px;

  .my-block {
    width: 30vw;
  }

  .main-page {
    padding-top: 150px !important;
    margin-left: 0;
  }

  // .ant-btn {
  //   width: 200px;
  //   &.ant-btn-primary {
  //     width: 300px;
  //   }
  // }
}

.registration-page-wrapper {
  .main-page {
    padding-top: 100px !important;
    width: 100%;
  }
}

.mobile-apps-list {
  img {
    width: 130px;
  }
}

#userForm {
  .my-block {
    margin-left: 0;
    height: auto;
  }
}

//pricing
.pricing-section {
  display: flex;
  justify-content: center;
  min-height: 100vh;

  .back-to-login {
    position: absolute;
    left: 25px;
    top: 30px;
    display: flex;
    align-items: center;
    color: var(--mainColor);
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;

    span {
      margin-right: 5px;
      font-size: 10px;
    }
  }

  .pricing-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    color: $textColor;
    padding-top: 70px;

    .pricing-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .text-h2 {
        font-size: 2rem;
        color: $darkBlue;
        font-weight: 500;
        spam {
          color: var(--mainColor);
        }
      }
      .text-regular {
        font-size: 15px;
        font-weight: 400;
        width: 70%;
      }
    }

    .cards-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 20px;

      .cards-padding {
        padding: 15px;
        width: 25%;
        display: flex;

        .cards {
          width: 100%;
          padding: 40px 23px 30px;
          margin-bottom: 50px;
          border-radius: 30px;
          box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.05);
          background-color: $white;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          p {
            margin-top: 0;
            margin-bottom: 1rem;
          }

          h5 {
            font-size: 21px;
            font-weight: 500;
            margin: 0 0 20px;
            color: $darkBlue;
          }

          .price {
            display: flex;
            height: 58px;
            margin-left: -23px;
            margin-bottom: 50px;

            .usd {
              font-size: 12px;
              margin-top: 10px;
            }

            .big {
              font-size: 53px;
              font-weight: 300;
              color: $darkBlue;
              height: 58px;
            }
          }

          .italic {
            font-size: 12px;
            font-style: italic;
            opacity: 50%;
            padding-top: 12px;
          }

          .line {
            width: 100%;
            height: 2px;
            background-color: $borderColor;
            margin-bottom: 20px;
          }

          .package-feature {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            margin-bottom: 10px;
          }

          button {
            margin-top: 20px;
            width: 209px;
            height: 44px;
            background-color: var(--mainColor);
            color: $white;
            font-size: 12px;
            box-shadow: 0 3px 55px rgba(116, 116, 116, 0.16);
            border-radius: 60px;
            &:hover {
              background-color: $orange;
            }
          }
          .ant-btn {
            border-radius: 60px !important;
          }

          .button-main {
            margin-top: 20px;

            .main-btn {
              height: 44px;
              width: 209px;
              border-radius: 60px !important;
              padding: 0 20px;
              background-color: var(--mainColor);
              color: $white;
              border-radius: 11px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 600;
              font-size: 12px;
              border-style: none;
              cursor: pointer;
              text-decoration: none;

              &.orange {
                background-color: $white;
                color: var(--mainColor);
              }
              &:hover {
                background-color: $orange;
                color: $white;
              }
            }
          }

          .text-padding {
            margin-top: 27px;
            margin-bottom: 0;
            font-weight: 500;
            line-height: 16px;
          }

          .text-sm {
            margin-top: 21px;
            font-size: 12px;
            width: 99%;
            text-align: center;
          }

          .tel {
            width: 209px;
            height: 40px;
            border-radius: 100px;
            background-color: rgba(255, 255, 255, 0.32);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 29px;
            margin-bottom: 14px;
            color: white;
            text-decoration: none;
            font-size: 16px;
            &:hover {
              color: $orange;
            }

            svg {
              margin-right: 10px;
            }
          }

          &:hover {
            background-color: fade($blue, 20%);
            cursor: pointer;
            -webkit-transition: background-color 2s ease-out;
            -moz-transition: background-color 2s ease-out;
            -o-transition: background-color 2s ease-out;
            transition: background-color 2s ease-out;
            transform: translateY(-10px);
          }
        }
        .cards-last {
          background-image: linear-gradient(#1f409a, #105bcc, #0076ff);
          color: $white;
          .text-h5 {
            color: $white;
          }
        }
      }

      :last-child {
        margin-right: 0;
      }
    }

    .contact {
      background-color: $yellow;
    }
  }
}

.user-page-edit-wrapper {
  .two-input {
    .ant-form-item {
      width: 100%;
      .two-inputs-num {
        background-color: #f0f0f0;
      }
    }

    div {
      &:last-child {
        width: 100%;
      }
    }
  }
}

@import './colors.scss';
@import './variables.scss';

.ant-picker .ant-picker-clear {
  background: transparent !important;
}

.ant-picker-range,
.ant-btn {
  border-radius: $b-radius !important;
  font-weight: bold;
  // width: 100%;
  font-size: 14px;
  height: 40px;

  &.ant-btn-primary {
    background-color: var(--mainColor);
    color: $white;
    border: none;
  }

  &.ant-btn-cancel {
    border: 1px solid var(--mainColor);
    color: var(--mainColor);
    background-color: transparent;
  }

  &.ant-btn-filter {
    background-color: $componentBackground;
    border-style: none;
    color: $textColor;
    display: flex;
    align-items: center;
    justify-content: center;
    &.filter-active {
      background-color: $orange;
      color: $white;
      .anticon {
        svg {
          path {
            fill: $white;
          }
        }
      }
    }
  }

  &.ant-btn-circle {
    max-width: 40px;
    min-width: 40px;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--mainColor);
  }
}

.ant-picker-range,
.ant-input,
.ant-input-affix-wrapper,
.ant-input-number {
  border-radius: $b-radius !important;
  background-color: $componentBackground;
  .ant-input {
    background-color: transparent;
  }
  .ant-input::placeholder {
    opacity: 1;
  }
}

.input-search {
  &:hover {
    border: none !important;
  }
  &::placeholder {
    color: red !important;
  }
}

.ant-input,
.input-time-picker,
.ant-input-number {
  width: 100%;
  border-radius: $b-radius !important;
}

.ant-input,
.input-time-picker,
.ant-input-number,
.ant-input-number-input,
.ant-select-selection-search-input,
.ant-select-selection-placeholder {
  font-size: 14px;
  font-weight: $fw-regular;
  color: $textColor;
  opacity: 1;

  &::placeholder {
    font-size: 14px;
    color: $textColor;
    opacity: 0.3;
    font-weight: $fw-regular;
  }
}

.ant-switch-checked {
  background-color: var(--mainColor);
}

.ant-picker-range,
.ant-input,
.ant-input-affix-wrapper,
.ant-input-number {
  border-color: transparent;
  box-shadow: none;
  border: 1px solid transparent;

  &:focus,
  &:hover {
    border-color: transparent;
    box-shadow: none;
    border: 1px solid transparent;
  }
}

.ant-select-selector,
.ant-switch:focus {
  border-color: none;
  box-shadow: none;

  &:focus,
  &:hover {
    border-color: none;
    box-shadow: none;
  }
}

.ant-input-number-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid transparent !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper-focused,
.ant-input-number-focused {
  border-color: none;
  box-shadow: none;
  border: 1px solid var(--mainColor) !important;
  background-color: fade($blue, 5%);
  .anticon {
    color: var(--mainColor);
  }
}

textarea.ant-input {
  &:focus {
    border: 1px solid var(--mainColor);
    background-color: fade($blue, 5%);
    box-shadow: none;
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid transparent;
  box-shadow: none !important;
  &:hover {
    border: 1px solid transparent;
  }
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid var(--mainColor);
  background-color: fade($blue, 5%) !important;
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-form-item-label {
  padding: 3px 5px !important;
  // height: 40px !important;
  color: $labelColorGrey;
  font-size: 12px;
  font-weight: $fw-regular;

  .ant-form-item-required {
    &::before {
      right: -8px;
      position: absolute;
      color: $labelColorGrey !important;
      margin-right: 0 !important;
    }
  }
}

textarea {
  min-height: 40px !important;
}

.ant-form-item {
  margin-bottom: 5px;
}

.ant-form-item-with-help {
  margin-bottom: 0;
}

.ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split {
  font-size: 12px;
  min-height: fit-content;
}

.ant-form-item-has-error {
  textarea.ant-input {
    box-shadow: none;
  }
}

.ant-switch-checked {
  background-color: var(--mainColor) !important;
  // background-color: red !important;
}

.ant-picker-range {
  min-width: 230px;
  max-width: 330px;
}

.input-search {
  background-color: $componentBackground;
  border: none;
  min-width: 100px;

  input {
    background-color: transparent;
    &::placeholder {
      color: rgba($color: #000, $alpha: 0.25);
    }
  }
}

.ant-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ant-input-group-addon {
  border-style: none;
  background: none !important;
  border: none !important;
  border-radius: 0px !important;
  padding-left: 15px !important;
  .my-select-wrapper {
    margin-right: 1px;
  }
  .my-select-wrapper.phone-number {
    margin-right: 1px;
    .ant-select-arrow {
      right: 15px;
    }
  }
}

.input-phone-number {
  input {
    height: 27px !important;
    width: 100%;
  }
}

.ant-form {
  .my-row {
    margin-bottom: 15px;
  }
}

.ant-checkbox-wrapper {
  .ant-checkbox {
    cursor: pointer;
    .ant-checkbox-inner {
      border-color: var(--mainColor);
    }
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: var(--mainColor);
      }
    }
  }
}

.ant-upload {
  cursor: pointer;
}

.ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ant-btn {
    &.ant-btn-lg {
      width: 200px;
    }
  }
}

.ant-popover-content {
  .ant-popover-inner-content {
    .ant-popover-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .ant-btn {
        width: 70px;
      }
    }
  }
}

.my-select-item {
  width: 100%;
}

.ant-input-number-input {
  padding: 0px 11px !important;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 38px;
}

@media screen and (max-width: 480px) {
  .ant-picker-dropdown {
    width: 330px;
    .ant-picker-datetime-panel {
      width: 330px;
      padding: 0;
      margin: 0;
    }

    .ant-picker-header {
      padding: 0;
      margin: 0;
    }

    .ant-picker-panel-container {
      .ant-picker-time-panel-column {
        padding: 0;
        margin: 0;
        // padding-right: 15px !important;
        width: 40px !important;
        overflow-y: auto;
      }
      // .ant-picker-time-panel {
      //   // margin-top: 60px;
      // }
      .ant-picker-time-panel-cell-inner {
        padding: 0;
        margin: 0;
      }
      // .ant-picker-header-view {
      //   // display: none;
      // }
      .ant-picker-body {
        padding: 0;
        margin: 0;
      }
      .ant-picker-content {
        padding: 0;
        margin: 0;
      }
      font-size: 13px;
    }
  }
}

.my-select-item {
  width: 100%;
  a {
    color: #fff !important;
  }
}

@import '../../../styles/colors.scss';
@import '../../../styles/variables.scss';
@import './style.scss';

// .ant-drawer-left-menu-mobile {
//   z-index: 1002;

//   .ant-drawer-body {
//     padding: 0;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     .logo {
//       padding: 30px 30px 0px 20px;
//     }

//     .ant-dropdown-trigger {
//       padding: 15px 15px 30px 15px;
//       position: relative;
//       color: $white;
//       font-size: 14px;
//       font-weight: $fw-medium;
//       cursor: pointer;
//       display: flex;
//       align-items: center;
//       .ant-avatar {
//         background-color: #ccc;
//         min-width: 40px;
//       }
//       .name {
//         margin: 0 5px;
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//       }
//     }
//     .ant-menu {
//       background-color: transparent;
//       border: none;
//       width: 100%;
//       color: $white;
//       font-weight: $fw-medium;
//       font-size: 14px;
//       padding-left: 20px;

//       .ant-menu-item {
//         margin: 0;
//         padding-left: 28px !important;
//         height: 70px;
//         display: flex;
//         align-items: center;

//         .anticon {
//           display: flex;
//           margin-right: 0px;
//           &.ant-badge {
//             // right: 73px;
//             // position: absolute;
//             sup {
//               background-color: $badgeColor;
//               font-size: 10px;
//               padding-top: 3px;
//               font-weight: bold;
//               box-shadow: none;
//               margin: -3px;
//             }
//           }
//         }

//         a {
//           color: $white;
//           font-weight: $fw-medium;
//           font-size: 14px;
//           padding-left: 20px;
//         }

//         &:after {
//           display: none;
//         }

//         &:hover,
//         &.ant-menu-item-selected {
//           background-color: fade($lightGrey, 18%);
//           .anticon {
//             svg {
//               g {
//                 opacity: 1;
//               }
//             }
//           }
//         }
//       }

//       .ant-menu-submenu {
//         .ant-menu-submenu-title {
//           margin: 0;
//           padding-left: 28px !important;
//           height: 70px;
//           display: flex;
//           align-items: center;
//           &:active {
//             background-color: transparent;
//           }

//           span {
//             color: $white;
//             font-weight: $fw-medium;
//             font-size: 14px;
//             padding-left: 20px;

//             &.anticon {
//               padding-left: 0;
//               margin-right: 0;
//             }
//             &.ant-badge {
//               sup {
//                 background-color: $badgeColor;
//                 font-weight: bold;
//                 box-shadow: none;
//                 right: -127px;
//                 top: 8px;

//                 .ant-scroll-number-only {
//                   font-size: 10px;
//                   display: inline-block;
//                   padding: 0;
//                 }
//               }
//             }
//           }

//           .ant-menu-submenu-arrow {
//             &::after,
//             &::before {
//               background-color: $white;
//               background-image: none;
//             }
//           }
//         }

//         .ant-menu-sub {
//           .ant-menu-item {
//             a {
//               padding-left: 42px;
//               white-space: normal;
//               line-height: 20px;
//             }
//           }
//         }

//         .ant-menu {
//           .ant-menu-item {
//             padding-left: 22px !important;
//           }
//         }
//       }
//       &.ant-menu-inline-collapsed {
//         .anticon {
//           &.ant-badge {
//             sup {
//               min-width: 17px;
//               height: 17px;
//               line-height: 17px;
//               font-size: 8px;
//               right: -3px;
//               top: 0;
//             }
//           }
//         }
//       }
//     }
//   }
// }

@media screen and (max-width: 480px) {
  .header-space {
    padding-right: 5px;
    padding-left: 5px;
  }
}

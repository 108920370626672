@media screen and (max-width: 480px) {
  .ant-drawer-body {
    padding: 10px !important;
    .ant-picker {
      width: 100% !important;
      max-width: none !important;
    }
  }

  .mobile-list-header {
    //     height: 50vh;
    //     .filter-button {
    //       background-color: transparent;
    //       margin-right: 10px !important;
    //       width: 50px;
    //       height: 43px;
    //     }
    .search-response-input {
      margin-left: 50px !important;
    }
  }
}

@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.rating-view-list-item {
  @include shadowMain();
  border-radius: $b-radius;
  padding: 15px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  .list-item {
    margin-right: auto;
    .list-item-title {
      font-size: 12px;
      font-weight: $fw-regular;
      color: $lightGray;
    }
    .list-item-info {
      color: $textColor;
      font-size: 14px;
      font-weight: $fw-medium;
      display: flex;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        font-weight: $fw-regular;
        margin-left: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .list-item-info.order-number {
      cursor: pointer;
      color: $blue;
    }
    &.list-item-small {
      max-width: 10%;
    }
    &.order-number {
      min-width: 100px !important;
      // background-color: red;
    }
    &.list-item-large {
      max-width: 25%;
    }
    &.order-customer {
      width: 200px !important;
    }
  }
}

.rating-view-list-item .list-item.list-item-large.message {
  // background-color: red;
  width: 100%;
  .list-item-info-with-read-more {
    display: flex;
    align-items: center;
    gap: 10px;
    .list-item-all-text {
      color: $textColor;
      font-size: 14px;
      font-weight: $fw-medium;
    }
    .list-item-info {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
    }
    .read-more-link {
      white-space: nowrap;
    }
  }
}

@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

@media screen and (max-width: 991px) {
  .create-address-wrapper {
    flex-direction: column;
    .address-map-wrapper {
      margin: 0 0 20px 0;
      width: 100%;
    }
    .main-page {
      width: 100%;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .create-address-wrapper {
    .address-map-wrapper {
      padding: 0 15px 20px 15px;
    }
  }
}

@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.api-list-card {
  cursor: pointer;
}
.header-space {
  .add-api-code-btn {
    width: fit-content;
    // min-width: 160px;
  }
}
.apicode-view {
  width: 100%;
  box-shadow: 0 3px 6px fade(#000, 16%);
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  .my-row {
    padding: 10px 0;
    &:nth-child(1) {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  .first-item {
    color: $labelColorGrey;
    font-weight: 400;
    font-size: 12px;
  }
  .second-item {
    font-weight: 500;
    font-size: 12px;
    color: $blue;
  }
  .copy-item {
    display: flex;
    align-items: center;
    .anticon {
      cursor: pointer;
      margin-left: 5px;
      color: $blue;
    }
  }
}
.first-row {
  .card-name {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .edit-delete-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
    width: 100%;
    .edit-icon {
      display: flex;
      margin-left: 8px;
      cursor: pointer;
    }
  }
}

.second-row {
  display: flex;
  flex-direction: column;
  .card-name {
    align-self: flex-start;
    margin-bottom: 10px;
  }
  .api-card-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
  }
  .generate-btn {
    width: fit-content;
  }
}

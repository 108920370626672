.InfiniteScrollParent {
  .ant-list-item {
    margin-right: 10px;
  }
}
.myInfiniteList {
  padding-bottom: 20px;
  .myInfiniteListChildren {
    font-size: 12px;
    padding: 0;
    // width: 100% !important;
  }
}

@import '../../../styles/variables.scss';

.ant-modal.my-modal {
  .ant-modal-content {
    border-radius: $b-radius;
  }
  .ant-modal-body {
    padding-bottom: 15px;
  }
}

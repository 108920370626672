@import '../../../styles/colors.scss';
@import '../../../styles/variables.scss';

.input-time-picker {
  min-width: 200px;
  background-color: $componentBackground;
  border-radius: $b-radius;
  height: $h-component !important;
  border: none;

  .ant-picker-input {
    cursor: pointer;
    .ant-picker-clear {
      background-color: $componentBackground;
    }
  }

  &:hover {
    border: none;
  }
  &:focus {
    border: none;
  }
}
.input-time-picker.ant-picker-focused {
  border: 1px solid $blue;
  box-shadow: none;
}

@import '../../../styles/colors.scss';
@import '../../../styles/variables.scss';

.my-select-wrapper {
  min-width: 190px;
  .ant-select-selector {
    height: $h-component !important;
    background-color: $componentBackground !important;
    padding: 0 10px !important;
    border-radius: $b-radius !important;
    align-items: center;
    .ant-select-selection-item {
      font-weight: $fw-regular;
      font-size: 14px;
      color: $textColor;
      opacity: 1;
      padding-right: 28px;
    }
  }
}

.ant-select-dropdown {
  padding: 0;
}

.ant-select-item-option {
  width: 100%;
  &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: $selectOptionBGColor;
    color: $white;
  }
}

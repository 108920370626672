@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.secondary-text {
  color: rgba($color: #474657, $alpha: 0.7);
  font-size: 12px;
  padding: 1px;
  .ant-badge-count {
    color: rgba($color: #474657, $alpha: 0.7) !important;
  }
}

.my-card-block-wrapper {
  background-color: $white;
  padding: 15px;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border: 2px solid transparent;
  .my-row {
    overflow: hidden;
  }
  .my-card-header-row {
    width: 100%;
    margin-right: 0;
    .my-row {
      .button-trigger {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 13px;
          height: 13px;
        }
      }
    }
    .my-card-header-right-content {
      width: 45%;
      flex-wrap: wrap;
      justify-content: flex-end;
      padding-right: 6px;
      .weigth {
        margin-right: 5px;
      }
      .length {
        margin-left: 5px;
      }
      &.active {
        border: 2px solid $blue;
        background-color: fade($blue, 10%);
      }
    }
  }
  .customer-name {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .my-card-shared-item {
    margin: 10px 0;
    text-transform: uppercase;
    background-color: $blue;
    width: fit-content;
    padding: 5px 15px;
    border-radius: 100px;
    color: $white;
  }

  .my-card-not-deleved-item {
    margin: 10px 0;
    text-transform: uppercase;
    background-color: $orange;
    width: fit-content;
    padding: 5px 15px;
    border-radius: 100px;
    color: $white;
  }

  &.splitted-card {
    &:hover,
    &.active {
      border: 2px solid $blue;
      background-color: fade($blue, 10%);
      box-shadow: none;
    }
  }
  &.not-delivered-card {
    &:hover,
    &.active {
      border: 2px solid $orange;
      background-color: fade($orange, 10%);
      box-shadow: none;
    }
  }
  &:hover,
  &.active {
    border: 2px solid $blue;
    background-color: fade($blue, 10%);
    box-shadow: none;
  }
}

.my-list-panel-wrapper {
  .my-card-block-wrapper {
    .card-number:before {
      content: '';
      position: absolute;
      width: 60%;
      height: 2px;
      bottom: 0;
      left: 0;
      background: $blue;
      visibility: hidden;
      border-radius: 5px;
      transform: scaleX(0);
      transition: 0.25s linear;
    }
    .card-number:hover:before,
    .card-number:focus:before {
      visibility: visible;
      transform: scaleX(1);
    }
  }
}

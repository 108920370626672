.my-list-grid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-list-item {
  margin-right: 20px;
}

@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.ready-select {
  .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border: 1px solid $green;
    background-color: fade($green, 10%) !important;
  }
  .ant-select-selector {
    background-color: fade($green, 10%) !important;
    .ant-select-selection-item {
      color: $green;
    }
  }
  .ant-select-arrow {
    color: $green;
  }
}

.packing-select {
  .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border: 1px solid $orange;
    background-color: fade($orange, 10%) !important;
  }
  .ant-select-selector {
    background-color: fade($orange, 10%) !important;
    .ant-select-selection-item {
      color: $orange;
    }
  }
  .ant-select-arrow {
    color: $orange;
  }
}

@media screen and (max-width: 480px) {
  .selectBranch {
    width: 100%;
  }
}

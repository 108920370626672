// @import './colors.scss';

$header-height: 64px;
$fw-medium: 500;
$fw-bold: 700;
$fw-regular: 400;
$b-radius: 11px;
$h-component: 40px;

@function fade($color, $amount) {
  $rgba: rgba(red($color), green($color), blue($color), $amount);
  @return $rgba;
}

@mixin shadow() {
  box-shadow: 0 1px 6px 3px rgba(51, 51, 51, 0.1);
}

@mixin shadowSecond() {
  box-shadow: 0px 3px 6px #00000029;
}

@mixin border() {
  border: 1px dashed #3e3d4d;
}

@mixin borderTop() {
  border-top: 1px solid rgba(112, 112, 112, 0.14);
}

@mixin gradiant() {
  background-image: linear-gradient(
    to left,
    $menuGreenGradiant2,
    $menuGreenGradiant1
  );
}

@mixin gradiantMain() {
  background-image: linear-gradient(
    to top,
    $gradientColorOne,
    $gradientColorTwo
  );
}

@mixin shadowMain() {
  box-shadow: 0 3px 6px fade($black, 16%);
}

@mixin vendor($property, $value) {
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -ms-#{$property}: $value;
  -o-#{$property}: $value;
  #{$property}: $value;
}

.my-manifest-lists-main-page {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 5px;
  overflow: hidden;

  .manifest-show-archive-section {
    max-height: calc(100vh - 100px);
    width: 100%;
    text-align: center;
  }

  :where(.css-dev-only-do-not-override-12upa3x).ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    :last-child {
    margin-right: 0;
  }
}

body {
  .privacy-delivery-wrapper {
    padding: 66px 20px 20px 20px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;

    strong {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      color: #2c2c43;
      font-size: 16px;
    }

    p {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 14px;
    }

    ul li a {
      color: #abd40b;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 991px) {
  .privacy-delivery-wrapper {
    padding-top: 110px;
  }
}

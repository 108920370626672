@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.my-list-section-wrapper {
  width: 100% !important;
  padding: 3px;
  .list-title {
    padding-right: 10px;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
  }
  .count-value {
    padding: 2px 10px 0px;
    background-color: $blue;
    color: $white;
    border-radius: 10px;
    min-width: 35px;
    text-align: center;
    font-size: 12px;
    height: 18px;
  }
}

@import '../../../styles/colors.scss';
@import '../../../styles/variables.scss';

.input-number-with-btn-wrapper {
  display: flex;
  border-radius: $b-radius;
  background-color: $componentBackground;
  height: 38px;
  padding: 5px;

  .before-input {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
  }
  .after-input {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-input-number {
    border: none !important;
    .ant-input-number-input-wrap {
      input {
        text-align: center;
        padding-bottom: 5px;
      }
    }
  }
  .ant-input-number.ant-input-number-focused {
    border: none !important;
    background-color: $componentBackground;
  }
  &:focus {
    border-color: 1px solid;
    border-right-width: 1px solid !important;
    background-color: rgba(0, 118, 255, 0.05);
  }
}

@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.my-block {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  border-radius: 10px;
  width: 100%;
  height: calc(100% - 60px);
  max-width: 650px;

  .my-block-title {
    margin-bottom: 15px;
    font-size: 16px;
    color: $textColor;
    font-weight: $fw-medium;
  }

  .spin-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}

@import '../../../styles/colors.scss';
@import '../../../styles/variables.scss';

.driver-packing-status-monitor-page-wrapper {
  display: flex;
  width: 100%;
  height: 100%;

  .ready-for-delevery-section {
    width: 60%;
    .my-section-title {
      background-color: $blue;
      color: $white;
    }
    .list-item-wrapper {
      color: $blue;
    }
  }

  .pending-section {
    width: 40%;
    .my-section-title {
      background-color: $orange;
      color: $white;
    }
    .list-item-wrapper {
      color: $orange;
    }
  }

  .my-section-title {
    text-align: center;
    padding: 15px 20px;
    font-size: 40px;
    font-weight: $fw-bold;
  }
  .list-item-wrapper {
    font-size: 35px;
    font-weight: $fw-bold;
  }
}

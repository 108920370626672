@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.api-list-card {
  cursor: pointer;
}

.apicode-view {
  width: 100%;
  box-shadow: 0 3px 6px fade(#000, 16%);
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  .my-row {
    padding: 10px 0;
    &:nth-child(1) {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  .first-item {
    color: $labelColorGrey;
    font-weight: 400;
    font-size: 12px;
  }
  .second-item {
    font-weight: 500;
    font-size: 12px;
    color: $blue;
  }
  .copy-item {
    display: flex;
    align-items: center;
    .anticon {
      cursor: pointer;
      margin-left: 5px;
      color: $blue;
    }
  }
}

.api-code-cancel-btn {
  margin-top: 20px;
  width: 105px;
}

@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

@media screen and (max-width: 1200px) {
  .pricing-section {
    .pricing-wrapper {
      .cards-wrapper {
        flex-wrap: wrap;
        justify-content: space-evenly;
        .cards-padding {
          width: 250px;
          .cards {
            button {
              width: 170px;
            }
            .tel {
              width: 170px;
            }
            .button-main {
              .main-btn {
                width: 170px;
              }
            }
            .price {
              .big {
                font-size: 45px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  // Start .pricing-section media styling
  .pricing-section {
    .pricing-wrapper {
      .cards-wrapper {
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 90%;
        .cards-padding {
          width: 50%;
          max-width: 300px;
          .cards {
            button {
              width: 200px;
            }
            .tel {
              width: 200px;
            }
            .button-main {
              .main-btn {
                width: 200px;
              }
            }
            .price {
              .big {
                font-size: 45px;
              }
            }
          }
        }
      }
    }
  }
  // End .pricing-section media styling

  .user-view-list-item {
    flex-wrap: wrap;
    justify-content: space-between;
    .draggable-icon {
      width: 33%;
      padding-left: 0;
      margin-right: 0;
    }
    .list-item {
      padding-right: 20px;
      &.list-item-large {
        max-width: 33.333%;
        width: 33.333%;
      }
      &.list-item-small {
        max-width: 33.333%;
        width: 33.333%;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .login-page-wrapper {
    .my-block {
      width: 40vw;
    }
  }
}

@media screen and (max-width: 700px) {
  // Start .pricing-section media styling
  .pricing-section {
    .pricing-wrapper {
      .cards-wrapper {
        width: 100%;
        .cards-padding {
          padding: 0;
          width: 50%;
          max-width: 250px;
        }
      }
    }
  }
  // End .pricing-section media styling

  .user-list-card-with-map {
    flex-direction: column;
    align-items: flex-start;
    .user-view-map {
      margin-left: 0;
      margin-top: 20px;
    }
  }
  .user-view-list-item {
    .draggable-icon {
      width: 50%;
    }
    .list-item {
      padding-right: 10px;
      &:nth-child(3),
      &:nth-child(4) {
        padding: 10px 0;
        padding-right: 10px;
      }
      .list-item-info {
        flex-direction: column;
        span {
          margin-left: 0;
        }
      }
      &.list-item-large {
        width: 50%;
        max-width: 50%;
      }
      &.list-item-small {
        width: 50%;
        max-width: 50%;
      }
    }
  }

  .login-page-wrapper {
    .my-block {
      width: 50vw;
    }
  }
}

@media screen and (max-width: 560px) {
  // Start .pricing-section media styling
  .pricing-section {
    .pricing-wrapper {
      .pricing-title {
        width: 70%;
      }
      .cards-wrapper {
        width: 100%;
        .cards-padding {
          width: 100%;
          max-width: 300px;
        }
      }
    }
  }
  // End .pricing-section media styling

  .login-page-wrapper {
    .my-block {
      width: 60vw;
    }
  }
}

@media screen and (max-width: 480px) {
  .pricing-section {
    .pricing-wrapper {
      .pricing-title {
        .text-regular {
          width: 100%;
        }
      }
    }
  }

  .ant-col {
    width: 100%;
  }
  .users-list-card {
    max-width: 100%;
    width: 100%;
  }

  .user-list-card-with-map {
    padding: 0 15px;
    margin-left: 0;
    .user-view-map {
      width: 100%;
    }
  }

  .login-page-wrapper {
    .my-block {
      width: 90vw;
    }
    .main-page {
      width: 100%;
      margin-left: 0;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .registration-page-wrapper {
    .main-page {
      padding-top: 50px !important;
    }
  }

  #userForm {
    .my-block {
      padding: 0;
    }
  }
}

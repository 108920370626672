@import '../../../styles/colors.scss';
@import '../../../styles/variables.scss';

.fields-wrapper-with-btn {
  max-height: 249px;
  overflow-y: auto;

  .fields-item-with-btn {
    border-top: 1px solid $borderColor;
    min-height: 76px;
    margin-bottom: 0px;
    padding-right: 10px;

    .field-item-left-with-btn {
      font-size: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 300px;
      .name {
        width: 100%;
        font-weight: $fw-medium;
        margin-bottom: 5px;
      }
      .store {
        width: 100%;
        font-size: 11px;
      }
    }

    .field-item-right-with-btn {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .input-with-helper-text {
        display: flex;
        flex-direction: column;

        .before-input {
          color: $lightGray;
          font-size: 13px;
        }

        .input-number-with-btn-wrapper {
          max-width: 104px;
          align-self: flex-end;
          .ant-input-number-input-wrap {
            input {
              font-weight: 600;
            }
          }
          .input-button {
            background-color: #1e419c;
            color: $white;
          }
          .ant-btn {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .helper-text {
          min-height: 10px;
          font-size: 10px;
          color: $lightGray;
          align-self: center;
          margin-bottom: 5px;
          width: max-content;
          max-width: 104px;
          .ant-badge-count {
            font-size: 9px;
            color: $lightGray;
          }
        }
      }
      .item-unit {
        padding-top: 8px;
        color: $textColor;
        font-size: 13px;
        font-weight: 600;
      }

      .fields-right-btn {
        margin-left: 10px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

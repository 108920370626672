@import '../../../styles/colors.scss';
@import '../../../styles/variables.scss';

#components-layout-demo-custom-trigger {
  .logo {
    height: 30px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }
}
.ant-layout {
  background-color: $white;
}

.site-layout {
  .site-layout-background {
    padding: 0px !important;
  }
}

.main-layout {
  height: 100vh;
  width: 100vw;
}

.ant-layout-header {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  // padding: 10px!important;
  width: fit-content;
  // position: absolute!important;
  position: absolute;
  // position: relative;
  z-index: 1000;
  padding: 10px 5px !important;
  .trigger {
    background-color: $white;
    display: flex;
    padding: 10px;
    border-radius: $b-radius;
    .anticon {
      transition: 0.5s;
      cursor: pointer;
      &.rotate-icon {
        transform: rotate(180deg);
      }
    }
  }
}

.Toastify__toast {
  border-radius: $b-radius;
}
.notify-div-icon-text {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 25px;
    height: 25px;
    margin-right: 20px;
    margin-left: 10px;
  }
}

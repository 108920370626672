@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.fields-wrapper {
  margin-top: 20px;

  .fields-item {
    min-height: 45px;
    border-top: 1px solid $borderColor;
    margin-bottom: 0px;
    width: 100%;

    .field-item-left {
      font-size: 12px;
      color: $labelColorGrey;
      display: flex;
      align-items: center;
    }

    .field-item-right {
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      text-align: right;
      padding-left: 10px;
      font-weight: $fw-medium;
    }
  }
}

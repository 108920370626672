@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.assign-list-wrapper {
  width: 100%;
  max-width: 100%;
  .my-list-wrapper-row {
    display: flex;
    width: 100%;
    max-width: 100%;
    .my-list-wrapper-row {
      display: flex;
      width: 100%;
      max-width: 700px;
    }
  }
  .my-list-panel-wrapper {
    width: 100%;
    max-width: 350px;
    // margin-top: 10px;
    .dropabel-list-item {
      overflow-y: scroll;
      // min-height: 500px;
      padding: 10px;
      height: calc(100vh - 200px);
      &.with-footer {
        height: calc(100vh - 350px);
      }
    }
    .select-date-range {
      .my-select-wrapper {
        min-width: 150px;
      }
    }
  }

  .my-list-item-product {
    background-color: fade($blue, 10%);
    padding: 10px;
    border-radius: 10px;
    .line {
      background-color: $borderColor;
      width: 2px;
      height: 20px;
    }
    .list-item {
      width: 100%;
      .anticon {
        width: 14px;
        margin-right: 5px;
        svg {
          width: 100%;
        }
      }
    }
  }

  .my-list-checked-wrapper {
    padding: 0 10px 10px 10px;
    .list-checked-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: fade($blue, 10%);
      padding: 10px;
      border-radius: 10px;
      .ant-form-item {
        margin-bottom: 0;
      }
      .checked-button {
        width: 40px;
        justify-content: center;
        display: flex;
        align-items: center;
        &:disabled {
          color: rgba(0, 0, 0, 0.25);
          border-color: #d9d9d9;
          background: #f5f5f5;
          text-shadow: none;
          box-shadow: none;
        }
      }
    }
  }

  .assign-map-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    max-width: calc(100% - 800px) !important;
    border-radius: 20px;
    position: fixed;
    right: 10px;
    top: 80px;
    transition: all 0.2s;
    overflow: hidden;
    width: 100%;
    &.menu-open-for-mapp {
      max-width: calc(100% - 950px) !important;
    }
    .ymaps-2-1-79-map {
      border-radius: 20px;
      overflow: hidden;
      width: 100% !important;
      .ymaps-2-1-79-inner-panes {
        border-radius: 20px;
      }
    }
  }
}

.my-modal.order-modal {
  width: 55vw !important;
  .modal-content-wrapper {
    .main-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 20px;
      position: relative;

      .left-side {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-right: 15px;
        border-right: 1px solid $borderColor;

        .my-card.users-list-card {
          background-color: $componentBackground;
          display: flex;
          flex-direction: column;

          .fields-wrapper {
            margin-top: 5px;
          }
          .fields-wrapper-with-btn {
            .fields-item-with-btn {
              background-color: red;
              padding-right: 10px;
            }
          }
        }
      }

      .right-side {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-left: 15px;

        .my-select-wrapper {
          width: 100%;
        }

        .spin-wrapper {
          align-items: flex-start;
        }

        .ant-input,
        .input-time-picker,
        .ant-picker-input {
          height: 35px;
        }

        .timeline-section {
          height: 92px;
        }
      }
    }

    .content-footer {
      .left-total {
        width: 50%;
        border-right: 1px solid $borderColor;
        padding-right: 15px;
        margin: 0;
      }
      .right-total {
        width: 50%;
        padding-left: 15px;
      }
      .total-kg-m3 {
        background-color: $componentBackground;
        border-radius: $b-radius;
        height: 44px;
        padding: 0 13px;
        margin-block-start: 15px;
        text-align: center;

        .ant-badge-count {
          font-weight: $fw-bold;
        }

        .left-text {
          font-size: 12px;
          color: $lightGray;
          display: flex;
          align-items: center;
        }
        .right-count {
          font-weight: $fw-bold;
          letter-spacing: -0.5px;
          display: flex;
          align-items: center;
          position: relative;
        }
      }
      .total-kg-m3 + .total-kg-m3 {
        margin-left: 15px;
      }
    }
  }
}

.my-list-panel-footer {
  padding: 10px 10px 0 10px;
  .negative {
    color: #ff0808;
    font-weight: bold;
  }
  .positive {
    color: $menuGreenGradiant2;
    font-weight: bold;
  }
  .my-footer-panel-buttons {
    .ant-btn-cancel {
      width: auto;
    }
  }
  .my-list-item-right-item {
    flex-wrap: wrap;
    justify-content: end;
  }
}

.my-list-panel-wrapper {
  .list-panel-title {
    padding: 10px;
    height: 60px;
    .list-panel-title-left-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .title {
        padding-right: 10px;
        // width: 100%;
        font-size: 16px;
        font-weight: 500;
      }
      .count {
        padding: 2px;
        background-color: $blue;
        color: $white;
        border-radius: 10px;
        min-width: 35px;
        text-align: center;
        font-size: 12px;
        height: 18px;
      }
      .header-value-kg {
        padding: 8px;
        background-color: rgba(0, 118, 255, 0.1);
        border-radius: 10px;
        min-width: 35px;
        text-align: center;
        font-size: 12px;
        // height: 18px;
      }
    }
    &.with-select {
      padding: 10px;
      .my-select {
        .my-select-wrapper {
          max-width: 190px;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .header-space.assign-page {
    .ant-picker,
    .my-select-wrapper {
      display: flex;
    }

    > div {
      display: flex;
    }
  }
}

@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.reasons-list-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center !important;
  // max-width: 280px;
  // min-width: 280px;
  // width: 280px;

  background-color: $white;
  height: auto;
  max-height: auto;
  min-height: 60px;

  font-size: 14px;
  font-weight: $fw-medium;
  padding: 15px;

  border-radius: $b-radius;

  @include shadowMain();

  // .first-row {
  //   margin-bottom: 10px;
  // }

  p {
    margin: 0;
  }

  .with-edit-button {
    display: flex;
    align-items: center;
    padding-right: 15px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }

    .edit-icon {
      display: flex;
      margin-left: 8px;
      cursor: pointer;
    }
  }

  .price-text {
    color: $blue;
  }

  .my-block-title {
    display: none;
  }

  .list-service-card-price-wrapper {
    .second-row {
      padding: 10px 0;
      height: 100%;
      p {
        color: $labelColorGrey;
        font-weight: $fw-regular;
        font-size: 12px;
      }
      .price-text {
        color: $blue;
        font-weight: $fw-medium;
      }
    }
    &:last-child {
      .second-row {
        padding-bottom: 0;
      }
    }
  }
}

.service-price-list-wrapper {
  h5 {
    font-size: 16px;
    color: #474657;
    font-weight: 500;
  }
  .add-price-list-row {
    &:nth-child(3) {
      .dynamic-delete-button {
        top: 42px;
      }
    }

    .full_with {
      &:nth-child(1) {
        width: 93.5%;
      }
    }
    &:last-child {
      .full_with {
        &:nth-child(1) {
          width: 93.5%;
        }
      }
    }
  }
  .ant-btn {
    margin-top: 10px;
  }
}

.add-price-list-row {
  align-items: flex-start;

  .my-select-wrapper {
    min-width: 190px;
  }

  .select-width {
    width: 100%;

    .ant-form-item {
      width: 100%;
    }
  }

  .full_with {
    width: 100%;
    .ant-form-item {
      width: 100%;
    }
  }

  .anticon-minus-circle {
    position: relative;
    top: 13px;
  }
}
.my-price-list-inputs {
  margin-bottom: 15px;
  .my-block-title {
    display: none !important;
  }
  .my-row {
    margin-bottom: 0;
  }
  .min-price-km-wrapper {
    width: 100%;
    display: flex;
    margin-top: 10px;
    .ant-form-item {
      width: 100%;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &:nth-child(2) {
    .add-price-list-row {
      .anticon-minus-circle {
        top: 41px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .add-price-list-row {
    .my-select-wrapper {
      min-width: 100%;
    }
  }
}

@media screen and (max-width: 480px) {
  .reasons-list-card {
    max-width: 100%;
    width: 100%;
  }
}

@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.my-filter-apply-texts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    color: $blue;
    font-size: 17px;
    font-weight: $fw-regular;
    cursor: pointer;
  }
}

@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

@media screen and (max-width: 991px) {
  .main-page {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 480px) {
  .main-page {
    margin-left: 3px;
    padding: 5px;
  }
}

@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.users-list-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // max-width: 280px;
  // min-width: 280px;
  // width: 280px;

  background-color: $white;

  height: fit-content;

  font-size: 14px;
  font-weight: $fw-medium;
  padding: 15px;

  border-radius: $b-radius;

  @include shadowMain();
  // cursor: pointer;

  p {
    margin: 0;
  }

  .with-edit-buttin {
    display: flex;
    align-items: center;
    padding-right: 15px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }

    .edit-icon {
      display: flex;
      cursor: pointer;
      padding: 10px;
    }
  }

  .ant-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    background-color: transparent;
  }

  .avatar-name {
    margin-bottom: 13px;
    .manifest-icon {
      padding: 8px;
      cursor: pointer;
      border-radius: $b-radius;
    }
  }

  .user-card-name-type-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    overflow: hidden;

    .transport-type-name-div {
      font-size: 12px;
      color: $labelColorGrey;
    }

    .user-name-div {
      font-size: 14px;
      font-weight: $fw-medium;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .div-label-value-wrapper {
    border-top: 1px solid $borderColor;
    .label-title {
      font-size: 12px;
      color: $labelColorGrey;
      height: 45px;
      align-items: center;
      display: flex;
    }

    .label-value {
      font-size: 12px;
      font-weight: normal;
      text-align: right;
      padding-left: 10px;
    }
  }

  .my-block-title {
    display: none;
  }
}
.driver {
  max-height: 100%;
  min-height: 195px;
  height: fit-content;

  .my-select {
    .my-select-wrapper {
      min-width: 130px;
      .ant-select-selector {
        width: 158px;
        cursor: pointer;
        height: 35px !important;
        text-align: center;
        .ant-select-selection-search-input {
          text-align: center;
        }
        .ant-select-selection-item {
          font-size: 13px;
        }
      }
    }
  }

  .label-value-with-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 158px;
    height: 35px;

    .input-right-btn {
      margin-left: 5px;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:disabled {
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        background: #f5f5f5;
        text-shadow: none;
        box-shadow: none;
      }
    }
    .gate-input {
      height: 35px;
    }
  }
}

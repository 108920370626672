@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.send-email-icon-wrapper {
  display: flex;
  align-items: center;
  .icon-block {
    width: 56px;
    height: 56px;
    @include shadowMain();
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 10px;
    cursor: pointer;
  }
}

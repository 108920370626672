@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.Toastify__toast--error {
  background-color: $notifyRed !important;
}
.Toastify__toast--success {
  background-color: $notifyGreen !important;
}
.Toastify__toast--warning {
  background-color: $notifyOrange !important;
}
.Toastify__toast--default {
  background-color: $notifyBlue !important;
}
.Toastify__toast-body {
  color: $white;
}

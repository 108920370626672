@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.rate-page-wrapper {
  height: 100%;
  overflow-x: hidden;
}

.rate-page-header {
  background-color: #003375;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  .header-logo {
    img {
      height: 50px;
    }
  }
}

.rate-page-body {
  height: calc(100vh - 120px);
  .rate-page-body-block-wrapper {
    max-width: 600px;
    margin: 60px auto 0;
    padding: 15px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    display: flex;
    flex-direction: column;
    .ant-rate {
      margin-bottom: 10px;
      margin-top: -10px;
    }
    .rate-label {
      font-weight: $fw-medium;
      font-size: 14px;
      margin-bottom: 7px;
    }
    .ant-form-item {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      .ant-form-item-control {
        width: 100%;
      }
    }
    .cant-review {
      height: 50px;
      margin-top: 10px;
      font-weight: $fw-regular;
      font-size: 14px;
      color: $textColor;
    }
  }
}

@media screen and (max-width: 480px) {
  .rate-page-body {
    .rate-page-body-block-wrapper {
      box-shadow: none !important;
    }
  }
}

.my-card {
  width: 100%;
  .branch-name-div {
    flex: 1;
  }
  .branch-button-wrapper {
    width: 20%;
    margin-bottom: 5px;
  }
}

@import './colors.scss';
@import './variables.scss';

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #c8c8c8;
  border-radius: 100px;
}

::-webkit-scrollbar-track {
  border-radius: 100px;
  background: #f1f1f1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.order-add-new-widget {
  @include gradiantMain();
  width: 60px;
  height: 60px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @include shadowMain();
  cursor: pointer;

  .anticon {
    transition: 0.2s;
  }

  .rotate {
    transform: rotate(45deg);
  }
}

.order-add-popover {
  padding-bottom: 0;

  // .ant-popover-arrow {
  //   display: none;
  // }

  .ant-popover-inner {
    background-color: transparent;
    box-shadow: none;

    .ant-popover-inner-content {
      padding: 0;
    }
  }

  .import-and-create-corders-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 15px;

    .ant-btn {
      width: auto;
      height: 42px;
      border-radius: 100px !important;
      font-weight: $fw-medium;
      border-style: none;
      @include shadowMain;

      & + .ant-btn {
        margin-top: 20px;
      }

      a {
        color: $textColor;
        margin-left: 8px;
      }

      &:hover {
        a {
          color: $gradientColorTwo;
        }
      }
    }
  }
}

input {
  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $componentBackground inset !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
// input[type='number'] {
//   -moz-appearance: textfield;
// }

.my-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;

  :last-child {
    margin-right: 0;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
}

.my-row > * {
  margin-right: 10px;
}

.divider-line {
  border-bottom: 1px solid $borderColor;
}

.user-profile-letters {
  font-size: 14px;
  color: var(--mainColor);
  font-weight: $fw-medium;
  background-color: fade($blue, 10%);
  width: 100%;
}

.ymaps-2-1-77-suggest-item {
  &:hover {
    background-color: $red;
  }
}

.header-space {
  min-height: 60px;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px;

  :last-child {
    margin-right: 0;
  }

  :first-child {
    margin-left: 0;
  }
}

.header-space > * {
  margin-right: 10px;
}

.filter-button {
  display: none;
}

// .ant-btn.ant-btn-filter {
//   display: none !important;
// }

.ymaps-2-1-77-map {
  border-radius: $b-radius;

  .ymaps-2-1-77-inner-panes {
    border-radius: $b-radius;
  }
}

.ant-popconfirm-buttons {
  display: flex;
  flex-direction: row;
  .ant-btn-primary {
    width: 100px !important;
  }
}

.ant-popconfirm-buttons button {
  width: 100px !important;
}

.delete-btn {
  cursor: pointer;

  .anticon-delete {
    color: $red;
  }
}

@media screen and (max-width: 480px) {
  body {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
}

@media screen and (max-width: 1300px) {
  .filter-button {
    display: block;
  }
  .ant-drawer {
    .ant-drawer-body {
      .ant-picker {
        margin-bottom: 20px;
      }

      .my-select-wrapper {
        width: 100%;
        margin-bottom: 20px;

        & + .my-select-wrapper {
          margin-top: 20px;
        }
      }
    }
  }
}

.no-access-page-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.spin-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.link-path {
  color: var(--mainColor);
  cursor: pointer;
}

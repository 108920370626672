@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.my-footer-panel-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  .ant-btn-cancel {
    width: 105px;
  }

  .ant-btn-primary {
    width: 120px;
  }
}

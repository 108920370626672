@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.header-space {
  .add-freight-btn {
    width: fit-content;
  }
}

.one-manifest-block-wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  margin-bottom: 15px;
  cursor: pointer;
  border-radius: $b-radius;
  justify-content: space-between;
  @include shadowMain();

  .number-div {
    font-weight: $fw-medium;
  }

  .date-div {
    color: $labelColorGrey;
    font-size: 12px;
  }

  .avatar-name {
    justify-content: flex-start;
    padding-top: 10px;

    .ant-avatar {
      margin-right: 8px;
      background-color: transparent;
      min-width: 26px;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.border-dash {
        border: 1px dashed #3e3d4d;
      }

      svg {
        fill: #3e3d4d;
        width: 13px;
        height: 13px;
        display: flex;
      }
    }

    .number-div {
      font-weight: $fw-medium;
      white-space: nowrap;
      width: 93%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .manifest-row-padding {
    height: 30px;
  }

  .callbtn-with-avatar {
    padding-top: 7px;

    .my-block-title {
      display: none;
    }
    .avatar-name {
      padding-top: 0;
      width: 78%;
    }
    .user-profile-letters {
      min-width: 26px;
      max-width: 26px;
      height: 26px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }
    .ant-btn {
      &.ant-btn-circle {
        max-width: 26px;
        min-width: 26px;
        height: 26px;
      }
      .anticon {
        width: 13px;
        height: 13px;
        display: flex;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.some-manifest-infos {
  height: 200px;
  min-height: 300px;

  .my-block-title {
    display: none;
  }

  .manifest-first-item {
    color: $labelColorGrey;
    font-size: 11px;
    font-weight: $fw-regular;

    .ant-badge.my-text-with-badge {
      color: $labelColorGrey;
      font-size: 12px;
      font-weight: $fw-regular;
      .ant-badge-count {
        color: $labelColorGrey;
        font-size: 10px;
        font-weight: $fw-regular;
      }
    }
  }

  .manifest-second-item {
    font-size: 12px;
    color: $textColor;
    font-weight: $fw-regular;
  }
  .manifest-row-padding {
    .negative {
      color: #ff0808;
      font-weight: bold;
      padding: 0 5px;
    }
    .positive {
      color: $menuGreenGradiant2;
      font-weight: bold;
      padding: 0 5px;
    }
  }
  .manifest-block-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 30px;
    .manifest-row-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% / 3);
    }
  }

  .my-left-border {
    border-right: 1px solid $borderColor;
  }
}

@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.divSelectedcolor {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;
  background-color: gray;
  border: 1px solid #d9d9d9;
}

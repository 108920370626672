@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.customer-edit-map-wrapper {
  width: calc(100% - 770px) !important;
  border-radius: $b-radius;
  padding-left: 15px !important;
  position: fixed;
  right: 5px;
  top: 10px;
  transition: all 0.2s;
  &.menu-open-for-map {
    width: calc(100% - 920px) !important;
  }
  .ymaps-2-1-79-map {
    border-radius: 20px;
    overflow: hidden;
    width: 100% !important;
    .ymaps-2-1-79-inner-panes {
      border-radius: 20px;
    }
  }
}

.addresses-list-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  max-width: 280px;
  min-width: 280px;
  width: 280px;

  background-color: $white;

  height: 95px;
  max-height: 95px;
  min-height: 95px;

  font-size: 14px;
  font-weight: $fw-medium;
  padding: 15px;

  border-radius: $b-radius;

  @include shadowMain();

  p {
    margin: 0;
  }

  .with-edit-buttin {
    display: flex;
    align-items: center;
    padding-right: 15px;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }
    .edit-icon {
      display: flex;
      margin-left: 8px;
      cursor: pointer;
    }
  }
  .price-text {
    color: $blue;
  }
}
.create-address-wrapper {
  display: flex;
  flex-direction: row;

  .main-page {
    width: 40%;
  }

  .address-map-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 60%;
    border-radius: $b-radius;
    margin-left: 20px;
    margin-top: 20px;
    overflow: hidden;
  }
}

@import '../../../styles/colors.scss';
@import '../../../styles/variables.scss';

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden;
}

.ant-layout-sider {
  margin: 10px 0;
  border-radius: 0 20px 20px 0;
  background-color: $blue !important;

  .logo {
    padding: 30px 30px 0px 17px;
    cursor: pointer;
  }

  .ant-dropdown-trigger {
    padding: 0 15px 30px 15px;
    position: relative;
    color: $white;
    font-size: 14px;
    font-weight: $fw-medium;
    cursor: pointer;
    display: flex;
    align-items: center;

    .ant-avatar {
      background-color: #ccc;
      min-width: 40px;
    }

    .name {
      margin: 0 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ant-menu {
      background-color: transparent;
      border: none;
      width: 100%;

      .ant-menu-item {
        margin: 0;
        padding-left: 28px !important;
        height: 70px;
        display: flex;
        align-items: center;

        .anticon {
          display: flex;
          margin-right: 0px;

          &.ant-badge {
            sup {
              background-color: $badgeColor;
              font-size: 10px;
              font-weight: bold;
              box-shadow: none;
            }
          }
        }

        .anticon-menuopen {
          &.ant-badge {
            padding-top: 5px;
            sup {
              padding-top: 2px;
            }
          }
        }

        a {
          color: $white;
          font-weight: $fw-medium;
          font-size: 14px;
          padding-left: 20px;
        }

        &:after {
          display: none;
        }

        &:hover,
        &.ant-menu-item-selected {
          background-color: fade($lightGrey, 18%);

          .anticon {
            svg {
              g {
                opacity: 1;
              }
            }
          }
        }
      }

      &.ant-menu-inline-collapsed {
        .anticon {
          &.ant-badge {
            sup {
              min-width: 17px;
              height: 17px;
              line-height: 17px;
              font-size: 8px;
              right: -3px;
              top: 0;
            }
          }
        }
        .ant-menu-title-content {
          width: 0;
          margin-left: 0;
        }
        .ant-menu-submenu-title span + span {
          width: 0;
          margin-left: 0;
        }
      }

      .ant-menu-submenu {
        &.ant-menu-submenu-selected {
          .ant-menu-submenu-title {
            background-color: fade($lightGrey, 18%);
          }
        }
        .ant-menu-submenu-title {
          margin: 0;
          padding-left: 28px !important;
          height: 70px;
          display: flex;
          align-items: center;
          &:active {
            background-color: transparent;
          }

          span {
            color: $white;
            font-weight: $fw-medium;
            font-size: 14px;
            padding-left: 20px;

            &.anticon {
              padding-left: 0;
              margin-right: 0;
            }
            &.ant-badge {
              sup {
                background-color: $badgeColor;
                font-weight: bold;
                box-shadow: none;
                right: -127px;
                top: 8px;

                .ant-scroll-number-only {
                  font-size: 10px;
                  display: inline-block;
                  padding: 0;
                }
              }
            }
          }

          .ant-menu-submenu-arrow {
            &::after,
            &::before {
              background-color: $white;
              background-image: none;
            }
          }
        }

        .ant-menu-sub {
          max-height: 330px;
          overflow-y: scroll;
          overflow-x: hidden;
          .ant-menu-item {
            a {
              padding-left: 46px;
              white-space: normal;
              line-height: 20px;
            }
          }
        }
        ::-webkit-scrollbar-track {
          background-color: inherit;
        }
        .ant-menu-sub ::-webkit-scrollbar-track {
          background-color: red;
          color: red;
        }

        .ant-menu {
          .ant-menu-item {
            padding-left: 22px !important;
          }
        }
      }
    }
  }
}

.ant-layout-sider-collapsed {
  .ant-layout-sider-children {
    .ant-menu {
      .ant-menu-item {
        height: 60px;
        margin: 5px;
        padding: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;

        .anticon {
          justify-content: center;
        }
      }

      .ant-menu-submenu {
        &.ant-menu-submenu-selected {
          .ant-menu-submenu-title {
            background-color: fade($lightGrey, 18%);
          }
        }

        .ant-menu-submenu-title {
          border-radius: 15px;
          margin: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 !important;
          height: 60px;

          span {
            padding: 0;
            display: flex;
            justify-content: center;
          }

          .anticon {
            &.ant-badge {
              sup {
                min-width: 17px;
                height: 17px;
                line-height: 17px;
                right: -3px;
                top: 0;
                background-color: $badgeColor;
                font-weight: bold;
                box-shadow: none;

                .ant-scroll-number-only {
                  font-size: 8px;
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }
}

.ant-menu-submenu-popup {
  .ant-menu-sub {
    .ant-menu-item {
      display: flex;
      align-items: center;
      // flex-direction: row-reverse;
      text-align: left;
      justify-content: space-between;

      .anticon {
        margin-right: 0;
        margin-left: 10px;

        &.ant-badge {
          sup {
            min-width: 17px;
            height: 17px;
            line-height: 17px;
            background-color: #ff9300;
            font-size: 10px;
            font-weight: bold;
            box-shadow: none;
          }
        }
      }

      &.ant-menu-item-selected {
        a {
          color: $blue;
        }
      }

      a:hover {
        color: $blue;
      }
    }
  }
}

@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.polygon-map-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 770px) !important;
  border-radius: $b-radius;
  padding-left: 15px !important;
  position: fixed;
  right: 5px;
  top: 10px;
  transition: all 0.2s;
  &.menu-open-for-map {
    width: calc(100% - 920px) !important;
  }
  .ymaps-2-1-79-map {
    border-radius: 20px;
    overflow: hidden;
    width: 100% !important;
    .ymaps-2-1-79-inner-panes {
      border-radius: 20px;
    }
  }
}

.header-space {
  .business-zone-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    max-width: 690px;
    width: 100%;

    .business-zone-title {
      font-size: 16px;
      color: $textColor;
      font-weight: $fw-medium;
    }
    .ant-btn {
      width: fit-content;
      min-width: 150px;
    }
  }
}

.polygon-list {
  .ant-spin-nested-loading {
    width: 100%;
    .polygon-list-item {
      box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
      border-radius: 15px;
      padding: 15px 20px;
      display: flex;
      align-items: center;
      width: 100%;
    }
    .list-item-name {
      color: $textColor;
      font-size: 13px;
    }
    .list-item-icons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 40px;
      .edit-icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .header-space {
    .business-zone-header {
      margin-top: 40px;
    }
  }
}

@import '../../../styles/colors.scss';
@import '../../../styles/variables.scss';

.my-list-grid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-list-item {
  margin-right: 20px;
}
.my-grid-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  // width: 100%;
  // height: calc(100% - 20px);
  // overflow: hidden;
  // overflow-y: auto;

  .my-grid-pagination {
    // margin: 0 30px 30px 10px;
    // padding-bottom: 20px;
    margin: 30px;
    // padding-bottom: 10px;
    // position: relative;
    // bottom: 20px;
  }
}

@import './variables.scss';

:root {
  --mainColor: #0076ff;
}

$MainGreen: #abd40b;
$menuGreen: $MainGreen;
$menuGreenGradiant2: #5cd40b;
$menuGrey: hsl(240, 10%, 38%);
// $menuTextGrey: #9292ab;
$menuGreenGradiant1: $MainGreen;
$page-header: #003375;
$strongText: #2c2c43;
$darkGrayBlue: #3e3d4d;

//

$white: #fff;
$red: #ff0000;
$secondRed: #ff0808;
$black: #000;
$opacityBlack: rgba(0, 0, 0, 0.25);
$menuTextGrey: fade($white, 80%);
$blue: #0076ff;
$secondBlue: #0076ff19;
$wildSand: #f5f5f5;
$alto: #d9d9d9;
$darkBlue: #1e419c;
$orange: #ff9300;
$yellow: #ffff00;
$lightGrey: #f4f7ff;
$lighterGray: #f0f0f066;
$badgeColor: $orange;
$gradientColorOne: #00e1db;
$gradientColorTwo: #00a7f4;
$textColor: #474657;
$componentBackground: #f0f0f0;
$selectOptionBGColor: #00a7f4;
$labelColorGrey: fade($textColor, 70%);
$green: #1fc97c;
$lightGray: #7e7d89;
$notifyRed: #e74c3c;
$notifyGreen: #07bc0c;
$notifyOrange: #f0ad4d;
$notifyBlue: #4dd2f0;

$borderColor: fade(#707070, 14%);
$switchColor: #ebebeb;

@import '../../styles/colors.scss';
@import '../../styles/variables.scss';

.notifications-list-item-wrapper {
  cursor: default;
  .notification-row {
    width: 100%;
    .list-item {
      &.list-item-small {
        max-width: 100%;
      }
    }
    .checkox-list-item-wrapper {
      display: flex;
      .title-with-action {
        .action-title {
          font-size: 12px;
          line-height: 16px;
          color: $labelColorGrey;
        }
        .checkbox-item {
          display: flex;
          align-items: center;
          .ant-checkbox-wrapper {
            .ant-checkbox + span {
              padding-right: 7px;
              padding-left: 0;
            }
          }
          .edit-template {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            color: $blue;
            cursor: pointer;
            &.disabled {
              color: $lightGray;
              cursor: not-allowed;
            }
          }
        }
        &:nth-child(1) {
          padding-right: 87px;
        }
        &:nth-child(2) {
          padding-right: 37px;
        }
      }
    }
  }
}

.textarea-with-notes-wrapper {
  .customer-sms-tags {
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 8px;
    color: $lightGray;
  }

  .ant-form-item {
    .ant-form-item-control-input-content {
      position: relative;
      overflow: hidden;
      border-radius: 10px;
      textarea {
        padding-right: 32px;
      }
    }
  }

  .count-textarea {
    position: relative;
    .show-textarea-count {
      position: absolute;
      right: 14px;
      top: 72px;
    }
  }

  .customer-sms-notes {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $lightGray;
    &.last {
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: 991px) {
  .notifications-list-item-wrapper {
    .notification-row {
      flex-direction: column;
      align-items: flex-start;
      .list-item {
        &.list-item-small {
          width: 100%;
          padding-right: 0;
          padding-bottom: 10px;
          .list-item-info {
            white-space: normal;
          }
        }
      }
      .checkox-list-item-wrapper {
        align-items: flex-end;
        .title-with-action {
          &:nth-child(2) {
            padding-right: 0;
          }
          &:nth-child(1) {
            padding-right: 40px;
          }
        }
      }
    }
  }
}
